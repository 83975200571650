.schedule_metting_slider_card {
    border: 2px solid #ededed;
    border-radius: 12px;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .schedule_metting_slider_card.active {
    border-color: #dc3545; /* Red border for active card */
  }
  
  .schedule_metting_row {
    padding: 2px 14px;
  }
  
  .schedule_metting_for_content p {
    padding-bottom: 0px;
    margin-bottom: 0;
    font-family: "Segoe UI";
    font-size: 15px;
    line-height: 29px;
    color: #6f6f6f;
  }
  
  .schedule_metting_for_content h5 {
    font-family: "GalanoGrotesqueMeduim";
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 0;
  }
  
  .schedule_metting_button .priority {
    border: none;
    background: #fff4f4;
    color: #a32532;
    border-radius: 20px;
    padding: 9px 26px;
    width: 48%;
    font-family: "Segoe UI";
    font-weight: 400;
    font-size: 16px;
  }
  
  .schedule_metting_button .table-number {
    border: none;
    background: #f2f2f2;
    color: black;
    border-radius: 20px;
    padding: 9px 26px;
    width: 48%;
    font-family: "Segoe UI";
    font-weight: 400;
    font-size: 16px;
  }
  
  .schedule_metting_in_min {
    font-family: "Segoe UI";
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    padding: 12px;
    border-radius: 0 0 12px 12px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .schedule_metting_slider_card.active .schedule_metting_in_min {
    background-color: #a32532;
    color: white;
  }
  
  .schedule_metting_for_content {
    padding-right: 0;
    padding-left: 7px;
  }
  
  @media screen and (min-width: 1220px) and (max-width: 1450px) {
    .schedule_metting_for_content h5 {
      font-family: "GalanoGrotesqueMeduim";
      font-weight: 400;
      font-size: 16px;
      line-height: 31px;
      margin-bottom: 0;
    }
  }
  
