@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/**/
@font-face {
  font-family: GalanoGrotesqueMeduim;
  src: url("../../../../public//GalanoFont/GalanoGrotesqueMedium.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Segoe UI;
  src: url("../../../../public/font/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root {
  --header-background-color: #101820;
  --common-background: #fff;
  --common-font: "Poppins", sans-serif;
}
.login_input_for_mobile_no div button:active {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
}
/* .login_input_for_mobile_no input {
  border: none;
  border-radius: 35px;
} */
/* .login_input_for_mobile_no input:focus {
  border: none !important;
  box-shadow: none !important;
} */
/* //User login otp section */
.store_input_field_field:focus {
  box-shadow: none;
  border: 1px solid black;
}

/* /// */

.login_popup_modal {
  padding-top: 2rem !important;
}
.login_your_account {
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #a32532;
}
.login_email_mobile_no label {
  font-family: "Segoe UI";
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  color: #2b2b2b;
}
.login_email_mobile_no input {
  border: none;
  padding: 10px 15px;
}
.login_email_mobile_no input:focus {
  border: none;
  box-shadow: none;
}
.login_email_mobile_no input::placeholder {
  font-family: "Segoe UI";
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
}
.login_popup_modal button {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  color: #a32532;
  border: 1px solid #a32532;
  font-size: 17px;
}
.login_popup_modal button:hover {
  background-color: #a32532;
  columns: #fff;
}
.login_email_mobile_no{
  padding-inline: 10px;
}
.user_login_top_right_cross_btn {
  right: 9px;
  top: -6px;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 41px;
}
.user_login_top_right_cross_btn button {
  border: none;
  background: transparent;
  color: #a32532;
  font-size: 38px;
  margin: -5px 3px 3px 4px !important;
}
.enter_digit_no_heading{
  font-family: "Segoe UI";
  font-size: 17px;
  line-height: 29px;
  font-weight: 400;
  color: #646464;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
