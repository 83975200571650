/* 
@font-face {
  font-family: GalanoGrotesqueMeduim;
  src: url("../../../../public//GalanoFont/GalanoGrotesqueMedium.otff")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Segoe UI;
  src: url("../../../../public/font/Segoe\ UI\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

.header_bell_icon {
  font-size: 9px;
  top: 17px;
  right: -13px;
}
.navbar_go_bell_icon {
  font-size: 19px;
}
.navbar_user_heading h6 {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 18px;
  font-weight: 400;
  text-transform: math-auto;
}
.navbar_user_heading h6 span {
  color: #a32532;
}
.navbar_user_heading svg {
  font-size: 22px;
}
.header_bell_icon {
  background-color: #a32532;
}
.notification_dropdown .dropdown-menu {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  min-width: 350px; /* Adjust width as needed */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: none;
  position: relative;
  left: -205%;
}
.notification_dropdown button:focus-visible {
  box-shadow: none;
  outline: none;
  border: none;
}
.notification_dropdown .dropdown-arrow {
  position: absolute;
  top: -9px;
  left: 47%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
.notification_dropdown button::after {
  display: none !important;
}
.notification_dropdown button span {
  background-color: #a32532;
    border-radius: 50%;
    top: -6px !important;
    font-size: 9px !important;
    /* left: 0px; */
    right: 9px;
}
.notifications_header {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 33px;
  color: #ededed;
  margin-bottom: 6px;
}
.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8 !important;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  gap: 13px;
  position: relative;
}
.notification-item:active {
  background: #f8f8f8;
}
.notification_with_svg {
  display: flex;
  position: absolute;
  right: 0;
  top: 8px;
  margin: 9px;
  color: black;
}
.notification-item img {
  width: 68px;
  height: 68px;
  margin-right: 10px;
}
.navbar_recent_notication {
  font-size: 20px;
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 400;
}
.notification_title {
  color: #6f6f6f;
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 500;
  font-size: 17px;
}

.notification-subtitle {
  font-size: 0.875rem;
  color: #999;
}

.bell-icon {
  position: relative;
  font-size: 1.5rem;
  color: #333;
}

.bell-icon .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #c72b26;
  color: white;
  border-radius: 50%;
  padding: 4px 7px;
  font-size: 0.75rem;
}
.dropdown_eight_min {
  font-family: "Segoe UI";
  font-size: 15px;
  color: #6f6f6f;
}
.notification_image_with_svg {
  display: flex;
}
.drop_down_notification {
  margin-bottom: 13px;
}
@media (max-width: 768px) {
  .navbar_left_logo img {
    width: 80%;
    height: 17px;
  }
  .navbar_user_heading h6 {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .navbar_user_heading h6 {
    font-size: 13px;
  }
}
