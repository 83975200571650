.no_metting_to_show {
  background-color: #fff6f7;
  height: 78vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  margin-top: 1rem;
}
.metting_main_section {
  padding-bottom: 0 !important;
  padding-top: 1rem !important;
}
/* .metting_animation_div{
flex-direction: column;
} */
.metting_animation_sec {
  flex-direction: column;
}
.no_metting_to_show div button {
  display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 10px 17px 15px;
    border-radius: 33px;
    margin-top: 0px !important;
    border: none;
    font-size: 18px;
    font-family: "GalanoGrotesqueMeduim";
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    color: #a32532;
    width: 267px;
    line-height: 17px;
    margin: auto;
}
.metting_deatils_accordian button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.started_in_mint_ago button {
  border: none;
  background: #fff5f4;
  padding: 5px 25px;
  border-radius: 25px;
  color: #ba5962;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 600;
  line-height: 23.98px;
}
.metting_details_svg svg {
  color: #a7a7a7;
}
.companies_svg_btn {
  flex-direction: row;
  gap: 15px;
}
.active-accordion .accordion-button {
  background-color: red;
  color: white;
}
.active-text {
  background-color: #a32532 !important;
  color: white !important;
}
