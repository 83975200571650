.main-content {
  padding: 20px;
}
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  border: 1px solid #e2dbdb;
  margin-top: 7px;
  border-radius: 7px;
  padding: 8px;
}

.main-wrapper {
  margin-top: 56px;
}


.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  border: 1px solid #e2dbdb;
  margin-top: 7px;
  border-radius: 5px;
  padding: 8px 6px;
  padding-left: 15px !important;
}
