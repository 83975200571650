/* Sidebar styles */
@font-face {
  font-family: GalanoGrotesqueMeduim;
  src: url("../../../../public//GalanoFont/GalanoGrotesque-Medium.ttf")format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Segoe UI;
  src: url("../../../../public/font/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.sidebar {
  position: fixed;
    top: 80px;
    width: 80px;
    height: 100vh;
    z-index: 1000;
    margin-left: 10px;
    background-color: #343a40;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
    padding-bottom: 15px !important;
    overflow: hidden;

}
/* .sidebar:hover {
overflow: auto;
} */
/* Custom scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 4px; /* Reduce scrollbar width */
}

.sidebar::-webkit-scrollbar-track {
  background: #2e2e2e; /* Scrollbar track color */
  border-radius: 10px; /* Round the edges of the track */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #a32532; /* Scrollbar thumb color */
  border-radius: 10px; /* Round the edges of the thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #a84a3e; /* Darker color on hover */
}

.sidebar::-webkit-scrollbar-thumb:active {
  background-color: #7a1b26; /* Even darker when clicked */
}
/* .main-wrapper{
  margin-bottom: 50%;
} */

.main-content {
  margin-left: 100px;
  padding: 20px;
}
.sidebar.hidden {
  transform: translateX(-120%);
}
.navbar_hemburger_button {
  display: none;
}

.sidebar.visible {
  transform: translateX(0);
}
.side_bar_all_svg_logo {
  width: 40px;
  height: 40px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.13)
}
.side_bar_all_heading {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 12px;
  line-height: 18.6px;
  font-weight: 400;
  color: #fff;
  margin: 6px 0px 0px;
}
/* .sidebar_main_content {
  opacity: 0.3;
  cursor: no-drop;
} */
@media (max-width: 768px) {
  .all_heading{
    font-size: 33px !important;
  }
  .side_bar_all_svg_logo{
    width: 36px !important;
    height: 36px !important;
  }
 .sidebar .align-items-center {
    align-items: unset !important;
}
  .sidebar {
    width: 160px !important;
    height: 100vh !important;
    border-radius: 0px !important;
    top: 70px  !important;
  }
  .side_bar_all_heading{
    margin: 9px 10px 0px !important;
  }
  .sidebar_main_content a{
    margin-bottom: 10px !important;
  }
  .sidebar_mobileviews{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;
    
  }
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.visible {
    transform: translateX(0);
    right: 0px;
  }
  .main-content {
    margin-left: 0px;
    padding: 0px;
    margin-top: 18px;
  }
  .sidebar {
    margin-left: 0px;
  }
  .navbar_hemburger_button {
    display: block;
  }
}
@media (min-width: 769px) {
  .sidebar {
    transform: translateX(0) !important;
  }

  .sidebar.hidden,
  .sidebar.visible {
    transform: none;
  }
}

.sidebar a:last-child {
  margin-bottom: 80px;
}

.blur {
  pointer-events: none !important;
  filter: blur(2px) !important;
}