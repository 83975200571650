.company_accodian_item h2 button {
  background: #f7f7f7 !important;
  box-shadow: none !important;
  padding-left: 0;
  padding-top: 0 !important;
  padding-right: 0;
  padding-bottom: 0;
}

.companies_accrodian .card {
  border: 17px solid #f7f7f7 !important;
  border-radius: 12px !important;
}
.companies_border_top {
  border-top: 13px solid #f7f7f7 !important;
}
.company_accodian_item {
  border: none !important;
}
.company_accodian_item h2 button span {
  background: #fff padding-box;
  padding: 6px 12px;
  border-radius: 24px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'GalanoGrotesqueMeduim';
  line-height: 23px;
  color: #000;
}
.companies_section_main_div {
  border: 3px solid whitesmoke;
  border-radius: 12px;
  padding: 10px;
}
.companies_beep_cart_img img {
  border: 2px solid #ececec;
  width: 135px;
  border-radius: 11px !important;
  padding: 8px;
}
.companies_select_all_div {
  margin-bottom: 10px;
}
.companies_beep_cart_img div h6 {
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 500;
  font-size: 18px !important;
  line-height: 29.45px;
}
.companies_beep_cart_img div small{
  font-family: "Segoe UI";
  font-weight: 400;
  color: #6f6f6f !important;
  font-size: 17px;
  line-height: 25.27px;
}
.companies_border_top p{
  font-family: "Segoe UI";
  font-weight: 400;
  color: #6f6f6f !important;
  font-size: 17px;
  line-height: 23.94px;
}
.companies_border_top p span{
  font-family: "Segoe UI";
  font-weight: 400;
  color: #1F1F1F !important;
  font-size: 18px;
  line-height: 23.94px;
}
.invest_controls p{
margin: 0px;
}
.invest_controls{
  background-color: #f6f6f6;
  border-radius: 100px;
  padding: 10px 0px 10px 16px;
  margin-left: 12px;
  margin-bottom: 10px;
}
.invest_controls ul{
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: end;
}
.expert_consumer{
  background: rgba(246, 246, 246, 1);
  margin-bottom: 10px;
  width: 100%;
  border-radius: 91px;
  padding: 8px 0px 2px 0px;
  margin-left: 3px;
}
.expert_consumer svg{
  color: rgba(163, 37, 50, 1);
    margin-left: 10px;
}

.expert_consumer ul li {
  display: flex;
  background: #fff;
  padding: 5px 17px;
  margin-right: 12px;
  background-repeat: 100px;
  border-radius: 100px;
  align-items: center;
}
.expert_consumer ul li p{
  margin: 0px;
  font-family: "GalanoGrotesqueMeduim";
    font-size: 12px;
    line-height: 18.6px;
    font-weight: 600;
    color: #000;
}
.expert_consumer ul{
  margin: 0px;
  padding: 0px;
    list-style: none;
    display: flex;
    justify-content: end;
}

.select_option{
  width: 100%;
}
#selected{
  background: rgba(255, 245, 244, 1);
  border: none;
  font-weight: 400;
}
#selected:hover{
color:#f6f6f6  !important;
background-color: #a32532 !important;
}
.metting_details_svg svg{ 
  cursor: pointer;
}
.reschdule_dropdown{
  position: absolute;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 11px 14px 18px;
    background: #fff;
    right: 60px;
    list-style: none;
    z-index: 9999;
    border-radius: 10px;
    cursor: pointer;
}
.reschdule_dropdown button:hover{
  background-color: #a32532;
  color: #fff;
}
.reschdule_dropdown button{
  border: 2px solid rgba(234, 234, 234, 1);
  background-color: #fff;
  padding: 4px 17px 10px;
  border-radius: 100px;
  color: #a32532;
  font-size: 16px;
  font-weight: 400;
  font-family: "GalanoGrotesqueMeduim";
  width: 100%;
  margin-top: 7px;
}
/* .company_accodian_item h2 button::after {
    background: #fff padding-box ;
    padding: 12px 12px ;
    border-radius: 24px;
  } */
