@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @font-face {
  font-family: GalanoGrotesqueMeduim;
  src: url("../../../public//GalanoFont/GalanoGrotesqueMedium.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Segoe UI;
  src: url("../../../public/font/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
.bg-secondary{
  background-color: #212529 !important;
}
.investor_slidesimg{
    width: 69px !important;
    height: 36px;
    object-fit: cover;
}
.all_page_for_top_heading {
  padding-top: 18px !important;
  padding-bottom: 1rem;
  padding-right: 0;
  padding-left: 0;
}
.all_heading {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  color: #ededed;
  margin-bottom: 0;
  font-family: "GalanoGrotesqueMeduim";
}
.profile_info_heading {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 22px;
  font-weight: 400;
  line-height: 34.1px;
  margin-bottom: 10px;
}
.home_beepkart_fa_pen img {
  border: 1px solid #ececec;
  border-radius: 10px;
}
.home_fa_pen_button {
  position: absolute;
  top: -57px;
  right: -14px;
  text-align: center;
  align-items: center;
  display: flex;
  height: 30px;
  width: 32px;
}
.home_beepkart_fa_pen {
  margin-bottom: 15px;
}
.all_input {
  background-color: #f6f6f6;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  padding: 10px 20px;
}
.all_input::placeholder {
  color: #aaa;
  font-size: 16px;
  font-family: "Segoe UI";
  color: #2f2f2f;
}
.all_input:focus {
  box-shadow: none;
  background-color: #f6f6f6;
}
.all_input_text_area {
  background-color: #f6f6f6;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  padding: 8px 14px;
  line-height: 14px;
}
.all_input_text_area::placeholder {
  color: #aaa;
  font-size: 16px;
  font-family: "Segoe UI";
  color: #2f2f2f;
}
.all_input_text_area:focus {
  box-shadow: none;
  background-color: #f6f6f6;
}
.profile_submit_btn button {
  border: 1px solid #a32532;
  padding: 5px 30px 9px;
  border-radius: 25px;
  background: #fff;
  color: #a32532;
  font-size: 16px;
  font-weight: 400;
  font-family: "GalanoGrotesqueMeduim";
}
.home_select button {
  border: 1px solid #a32532;
  padding: 5px 58px;
  border-radius: 25px;
  background: #fff;
  color: #a32532;
  font-size: 16px;
  font-weight: 400;
  font-family: "GalanoGrotesqueMeduim";
}
.home_select button:hover{
  border: 1px solid #a32532;
  color: #a32532;
  background: #FFF;
}
.home_select_know button:hover {
  border: 1px solid #5a5a5a;
  background-color: #fff;
  color: #5a5a5a;
}
.home_select_know button {
  border: 1px solid #5a5a5a;
  padding: 5px 45px 9px;
  border-radius: 25px;
  background: #5a5a5a;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: "GalanoGrotesqueMeduim";
}
.home_fa_pen_button {
  background-color: #a32532 !important;
  color: #fff !important;
}
.profile_investor_company_name svg {
  color: #a32532;
  font-size: 18px;
}
.profile_investor_company_name span {
  font-size: 18px;
  font-family: "Segoe UI";
  font-weight: 400;
  line-height: 29px;
}
.home_investor {
  padding: 8px 19px;
  border: 2px solid #ededed;
  border-radius: 11px;
}
.home_no_metting_to_show {
  background: #ffe8e8;
  padding: 10px 19px;
  border-radius: 10px;
}
.home_no_metting_to_show span {
  font-size: 18px;
  font-family: "Segoe UI";
  font-weight: 600;
  line-height: 29px;
}
.home_new_company {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.home_new_company_heading {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
}
.home_bottom_carousls {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.main_contant {
  width: 90%;
  margin: 34px auto 0px 100px;
}
.home_slider_img_content img {
  width: 125px;
  margin-bottom: 8px;
}
.home_bottom_carousls .slick-slider ul {
  display: none !important;
}
.home_slider_img_content h5 {
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}
.home_slider_img_content p {
  margin-bottom: 0;
  font-size: 19px;
  font-family: "Segoe UI";
  font-weight: 400;
  line-height: 29px;
}
.home_slider_img_content .home_slider_bonus_hub {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Segoe UI";
  font-weight: 400;
  line-height: 22px;
  color: #6f6f6f;
}
.home_slider_card {
  border: 2px solid #ededed;
  border-radius: 12px;
  padding: 15px;
}
.home_slider_main_section {
  padding-left: 0 !important;
}
.home_slider_last_section {
  padding-right: 0 !important;
}
.home_select button:hover{
background-color: #a32532;
color: #fff;
}
.profile_submit_btn button:hover
{
background-color: #a32532;
color: #fff;
}
@media screen and (min-width: 1220px) and (max-width: 1450px) {
  .home_select_know button {
  
    padding: 5px 40px 9px;
  }
  .home_select button {
  
    padding: 5px 45px 9px;
  }
}
@media only screen and (max-width: 768px) {
  .main_contant {
    width: 100%;
    margin: 30px auto 0px 0px;
  }
  .home_select_know button {
    padding: 5px 29px 9px;
   
  }
  .home_select button {
    padding: 5px 45px 9px;
    
  }
}
