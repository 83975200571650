@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&display=swap');

body{
    margin:0px;
    padding: 0px;
}

@font-face {
    font-family: 'Segoe UI';
    src: url(../../public/font/SegoeUI.ttf)  format('truetype');
}
.landing{
    background-image: url(../../public/image/bg.png);
    background-repeat: no-repeat;
    height: 100vh;
    font-family: "Oswald", system-ui !important;
    background-position: center;
}
.img_fluid{
    width: auto;
    height: 602px;
   
    object-fit: cover;

}
.text-align{
text-align: end;
}
.landing .logo{
    width: 316px;
    margin: 0px 0px;
}
.landing .header{
    padding-top: 20px;
}
.landing h1{
    color: rgba(163, 37, 50, 1)!important;
    box-shadow: 0px 4px 54px 0px rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 47px !important;
}

.landing h2{
    color: rgba(31, 31, 31, 1) !important;   
    box-shadow: 0px 4px 54px 0px rgba(255, 255, 255, 1);
    font-weight: 400 !important;
    text-align: center;
}
.landing p{
    font-family: 'Segoe UI' !important;
    color: rgba(111, 111, 111, 1);
    font-weight: 400 !important;
    text-align: center;
    line-height: 29px;
}
.landing .dashbaord_btn{
    text-align: center;
    width: 100%;
    /* border: none; */
    outline: none;
}
.header ul{
    padding: 0px;
    margin: 19px 0px 0px;
    list-style: none;
    display: flex;
    justify-content: start;
}
.header ul li{
    background: rgba(83, 83, 83, 1);
     margin-right: 10px;
     padding: 7px 14px;
    border-radius: 100px;
}
.header ul li a{
    color: #fff !important;
}
.header ul li:hover{
    background: rgba(163, 37, 50, 1)!important;
}
.dashbaord_btn:nth-child(2){
    margin-top:10px;
}
.landing .dashbaord_btn button{
    color: rgba(163, 37, 50, 1);
    /* font-family: 'Segoe UI' !important; */
    background-color: #fff;
    border: 1px solid rgba(163, 37, 50, 1);
    padding: 7px 33px 12px;
    border-radius: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    transition: 0.3s;
    margin-left: 10px;
    font-family: "GalanoGrotesqueMeduim";
    /* text-transform: capitalize; */
}
.dashbaord_btn button:hover{
    background-color: rgba(163, 37, 50, 1);
    color:#fff;
    transition: 0.3s;
}
.inner_section{
    margin: 85px 0px 0px;
    transform: translate(45px, 0px);
}
.right_side{
    justify-content: end !important;
}

.header ul li a .icon{
    transform: translate(0px, -2px);
}
@media only screen and (max-width:768px) {
    .landing h1 {
    font-size: 40px !important;
}
    .landing .logo {
        width: 230px !important;
        margin: 0px 0px;
    }
    .login_btn{
        margin-top: 10px;
        padding: 7px 26px 12px !important;
    }
    .copy_right{
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 14px;
    
    }
    .inner_section {
        margin: 41px 0px 0px;
        transform: translate(0px, 0px) !important;
    }
    .header p br{
        display: none;
    }
    .img_fluid {
        width: 100%;
        height: 333px;
        margin: 42px 0px 42px auto;
        object-fit: cover;
    }
    .header ul {
        justify-content: center !important;
    }
    .logo {
        width: 248px;
        margin: 0px auto;
    }
   .main_logo{
        text-align: center;
    }
}
.copy_text:hover{
    color: rgba(163, 37, 50, 1);
}
.landing a{
    text-decoration: none;
    font-family: 'Segoe UI' !important;
    color: rgba(111, 111, 111, 1);
    font-weight: 400 !important;
    text-align: center;
    line-height: 29px;
}
.copy_text{
    font-family: 'Segoe UI' !important;
    color: rgba(111, 111, 111, 1);
    font-weight: 400 !important;
    text-align: center;
    line-height: 46px;
    font-size: 17px;
    margin-bottom: 0px;
    margin-top: 21px;
}
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
    .img_fluid {
        width: auto;
        height: 635px;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .inner_section {
       
        transform: translate(0px, 0px) !important;
    }
    .inner_section p{
            width: 395px;
            margin: 20px auto;
        }
    
}