.company_accodian_item h2 button {
  background: #f7f7f7 !important;
  box-shadow: none !important;
  padding-left: 0;
  padding-top: 0 !important;
  padding-right: 0;
  padding-bottom: 0;
}

.companies_accrodian .card {
  border: 17px solid #f7f7f7 !important;
  border-radius: 12px !important;
}

.companies_border_top {
  border-top: 13px solid #f7f7f7 !important;
}

.company_accodian_item {
  border: none !important;
}

.company_accodian_item h2 button span {
  background: #fff padding-box;
  padding: 6px 12px;
  border-radius: 24px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'GalanoGrotesqueMeduim';
  line-height: 23px;
  color: #000;
}

.companies_section_main_div {
  border: 3px solid whitesmoke;
  border-radius: 12px;
  padding: 10px;
}

.companies_beep_cart_img img {
  border: 2px solid #ececec;
  width: 133px;
  border-radius: 11px !important;
  padding: 8px;
  height: 58px;
  object-fit: cover;
}

.companies_select_all_div {
  margin-bottom: 10px;
}

.companies_beep_cart_img div h6 {
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 500;
  font-size: 18px !important;
  line-height: 29.45px;
}

.companies_beep_cart_img div small {
  font-family: "Segoe UI";
  font-weight: 400;
  color: #6f6f6f !important;
  font-size: 17px;
  line-height: 25.27px;
}

.companies_border_top p {
  font-family: "Segoe UI";
  font-weight: 400;
  color: #6f6f6f !important;
  font-size: 17px;
  line-height: 23.94px;
}

.companies_border_top p span {
  font-family: "Segoe UI";
  font-weight: 400;
  color: #1F1F1F !important;
  font-size: 18px;
  line-height: 23.94px;
}

.invest_controls p {
  margin: 0px;
}

.invest_controls {
  background-color: #f6f6f6;
  border-radius: 100px;
  padding: 10px 0px 10px 16px;
  margin-left: 12px;
  margin-bottom: 10px;
}

.invest_controls ul {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: end;
}

.expert_consumer {
  background: rgba(246, 246, 246, 1);
  margin-bottom: 10px;
  width: 100%;
  border-radius: 91px;
  padding: 8px 0px 2px 0px;
  margin-left: 3px;
}

.expert_consumer svg {
  color: rgba(163, 37, 50, 1);
  margin-left: 10px;
}

.expert_consumer ul li {
  display: flex;
  background: #fff;
  padding: 5px 17px;
  margin-right: 12px;
  background-repeat: 100px;
  border-radius: 100px;
  align-items: center;
}

.expert_consumer ul li p {
  margin: 0px;
  font-family: "GalanoGrotesqueMeduim";
  font-size: 12px;
  line-height: 18.6px;
  font-weight: 600;
  color: #000;
}

.expert_consumer ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: end;
}

.select_option {
  width: 100%;
}

#selected {
  background: rgba(255, 245, 244, 1);
  border: none;
  font-weight: 400;
}

#selected:hover {
  color: #f6f6f6 !important;
  background-color: #a32532 !important;
}

/* .company_accodian_item h2 button::after {
    background: #fff padding-box ;
    padding: 12px 12px ;
    border-radius: 24px;
  } */


.companies_popup_radio_btn {
  background: #f6f6f6;
  padding: 9px 19px 10px 38px;
  border-radius: 24px;
}

.companies_popup_radio_btn label {
  font-family: "Segoe UI";
  font-weight: 400;
  color: #1f1f1f !important;
  font-size: 18px;
  line-height: 23.94px;
}

.companies_popup_all_section button {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 19px;
  line-height: 26px;
  font-weight: 400;
  padding: 3px 32px 10px;
  border-radius: 28px;
}

.login_popup_modal h5 {
  font-family: "GalanoGrotesqueMeduim";
  font-size: 22px;
  line-height: 36px;
  font-weight: 400;
}

.started_in_mint_ago button {
  margin-right: 10px;
}

.metting_date_and_time_table {
  font-weight: 600;
    color: #a32532 !important;
}