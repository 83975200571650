.re_schudule_svg {
  border: 1px solid #d3cfcf;
  border-radius: 50%;
  padding: 4px 5px;
  background: #fff;
}
.re_schudule_svg svg {
  font-size: 25px;
}
.re_schudule_request {
  font-size: 22px;
  font-family: "GalanoGrotesqueMeduim";
  font-weight: 400;
  line-height: 35px;
}
.re_schudule_reason {
  font-family: "Segoe UI";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.re_schudule_form_check {
  background: #f6f6f6;
  padding: 14px 35px;
  border-radius: 5px;
  margin-bottom: 0.7rem;
}

.re_schudule_form_check label {
  font-family: "Segoe UI";
  font-size: 18px;
  font-weight: 400;
  line-height: 23.94px;
}
.all_radio_btn:checked{
    background-color: #a32532 !important;
    border-color: #a32532 !important; 
}
.all_radio_btn:focus{
    background-color: #a32532 !important;
    border-color: #a32532 !important; 
    box-shadow: none !important;
    outline: none !important;
}
.all_page_for_top_main_heading{
    gap: 10px;
}

.re_schedule_textarea textarea{
    border-radius: 10px;
    font-size: 18px;
    padding: 8px 14px;
    line-height: 14px;
    border: 2px solid #eaeaea;
  }
  .re_schedule_textarea textarea::placeholder {
    color: #aaa;
    font-size: 18px;
    font-family: "Segoe UI";
    color: #2f2f2f;
    font-weight: 400;
  }
  .re_schedule_textarea textarea:focus {
    box-shadow: none;
    border-color: #eaeaea ;
  }